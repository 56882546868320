// theme
import palette from '../theme/palette';

// ----------------------------------------------------------------------

export const colorPresets = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#EBD6FD',
    light: '#B985F4',
    main: '#7635dc',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#fff'
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#D1FFFC',
    light: '#76F2FF',
    main: '#1CCAFF',
    dark: '#0E77B7',
    darker: '#053D7A',
    contrastText: palette.light.grey[800]
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#CCDFFF',
    light: '#6697FF',
    main: '#0045FF',
    dark: '#0027B7',
    darker: '#00137A',
    contrastText: '#fff'
  },
  // ORANGE
  {
    name: 'orange',
    lighter: '#FEF4D4',
    light: '#FED680',
    main: '#fda92d',
    dark: '#B66816',
    darker: '#793908',
    contrastText: palette.light.grey[800]
  },
  // RED
  {
    name: 'red',
    lighter: '#FFE3D5',
    light: '#FFC1AC',
    main: '#FF3030',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#fff'
  },
  // KYRGYSTAN
  {
    name: 'kyrgyzstan',
    lighter: '#0772c4',
    light: '#055593',
    main: '#0764ab',
    dark: '#054b81',
    darker: '#033962',
    contrastText: '#fff'
  },
  // LIBERIA
  {
    name: 'liberia',
    lighter: '#0772c4',
    light: '#055593',
    main: '#0764ab',
    dark: '#054b81',
    darker: '#033962',
    contrastText: '#fff'
  },
  // TCHAD
  {
    name: 'tchad',
    lighter: '#FEF4D4',
    light: '#FED680',
    main: '#fda92d',
    dark: '#B66816',
    darker: '#793908',
    contrastText: palette.light.grey[800]
  },
  // COMOROS
  {
    name: 'comoros',
    lighter: '#C8FACD',
    light: '#5BE584',
    main: '#00AB55',
    dark: '#007B55',
    darker: '#005249',
    contrastText: '#fff'
  },
  // CONGO
  {
    name: 'kobikisa',
    lighter: '#C8FACD',
    light: '#5BE584',
    main: '#00AB55', // Should be #009543
    dark: '#007B55',
    darker: '#005249',
    contrastText: '#fff'
  }, // RCA
  {
    name: 'rca',
    lighter: '#C8FACD',
    light: '#5BE584',
    main: '#00AB55', // Should be #009543
    dark: '#007B55',
    darker: '#005249',
    contrastText: '#fff'
  },
  // LESOTHO
  {
    name: 'lesotho',
    lighter: '#CCDFFF',
    light: '#6697FF',
    main: '#0045FF',
    dark: '#0027B7',
    darker: '#00137A',
    contrastText: '#fff'
  },
  // CAMBODIA
  {
    name: 'cambodia',
    lighter: '#0772c4',
    light: '#055593',
    main: '#0764ab',
    dark: '#054b81',
    darker: '#033962',
    contrastText: '#fff'
  },
  // DJIBOUTI
  {
    name: 'djibouti',
    lighter: '#D1FFFC',
    light: '#76F2FF',
    main: '#1CCAFF',
    dark: '#0E77B7',
    darker: '#053D7A',
    contrastText: palette.light.grey[800]
  }, // RCA
  {
    name: 'niger',
    lighter: '#C8FACD',
    light: '#5BE584',
    main: '#00AB55', // Should be #009543
    dark: '#007B55',
    darker: '#005249',
    contrastText: '#fff'
  },
  {
    name: 'niger_darker',
    lighter: '#C8FAD6',
    light: '#5BE49B',
    main: '#00A76F',
    dark: '#007867',
    darker: '#004B50',
    contrastText: '#fff'
  }
];

export const defaultPreset = colorPresets[0];
// export const purplePreset = colorPresets[1];
// export const cyanPreset = colorPresets[2];
// export const bluePreset = colorPresets[3];
// export const orangePreset = colorPresets[4];
// export const redPreset = colorPresets[5];
// export const kyrgyzstanPreset = colorPresets[6];
// export const tchadPreset = colorPresets[7];
// export const comorosPreset = colorPresets[8];
// export const lesothoPreset = colorPresets[9];
// export const cambodiaPreset = colorPresets[10];

export default function getColorPresets(presetsKey) {
  const colorPresetsObject = [];
  colorPresets.forEach((colorPreset) => {
    colorPresetsObject[colorPreset.name] = colorPreset;
  });

  return colorPresetsObject[presetsKey];
}
